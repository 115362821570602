import * as React from "react";
import {
  CafeAccess,
  CafeGallery,
  CafeInfo,
  Layout,
  PageTitle,
} from "../../components";
import Seo from "../../components/Seo";

const AwajiPage = () => {
  return (
    <Layout>
      <Seo title="AWAJI" />
      <PageTitle
        title="emo TOKYO Awaji"
        subTitle="焚き火をしながら海を眺められるhotでchillなカフェ"
      />
      <CafeInfo
        place="awaji"
        heading="焚き火をしながら海を眺められるhotでchillなカフェ"
        description={
          <p className="mb-6 lg:mb-10 text-sm leading-loose lg:text-base">
            神戸・大阪都心から車で約1時間。
            <br />
            マイクロツーリズムにピッタリのスポットとして話題沸騰中の淡路島西海岸にemo
            TOKYOがついに上陸。視界を遮るものが何もない、日本でも有数のサンセットと穏やかな瀬戸内海が眺める抜群のロケーション！
            <br />
            猪名川店同様、完全自家製の窯焼きPizzaや抹茶・チーズのケーキ、自慢の減農薬焼き芋に加え、新たにフィリーチーズステーキやオリジナルレシピのダルカレーなど個性豊かなemo
            TOKYOメンバーが考案した多彩なフードラインナップでおもてなしいたします。
          </p>
        }
        menuLink="https://drive.google.com/file/d/1wjYGnLQldfpf5ye1TOfnrd_8Px2N-3tW/view"
        reserveLink="https://airrsv.net/emotokyoawaji/calendar"
      />
      <CafeGallery place="awaji" />
      <CafeAccess
        map="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13162.778669275525!2d134.7925701!3d34.4345101!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3554bb735b2fc3a7%3A0x777e18f4baf51c01!2z54Sa44GN54Gr44Kr44OV44KnIGVtbyBUT0tZTyBBd2FqaQ!5e0!3m2!1sja!2sjp!4v1696308770842!5m2!1sja!2sjp"
        name="焚き火Cafe&Bar emo TOKYO Awaji"
        address="〒666-0252 兵庫県淡路市深草227-5"
        mapUrl="https://maps.app.goo.gl/BXKxVJiTXMRFdUuK9"
        tel="0799-70-7324"
        hours={
          <p className="block text-right">
            11:00〜20:00
            <br />
            <br />
            (定休日：木曜日)
          </p>
        }
        seats={
          <p className="block text-right">
            店内　12〜13席
            <br />
            <br />
            テラス　約10名
            <br />
            <br />
            焚き火ピット席　約16名
            <br />
            (1ピット / 最大8名)
          </p>
        }
        system={
          <p className="block text-right">
            焚き火ピット(予約優先制)
            <br />
            <br />
            通常利用　2,500円 / 人(ワンドリンク付)※
            <br />
            貸切利用　14,000円 / ピット
            <br />
            ※3日前までにご予約頂いた場合は全て500円オフ
          </p>
        }
        parking="7台＋近隣にある公園”緑の道しるべ”に5台ほど駐車可能"
        instagram="https://www.instagram.com/emo_tokyo_awaji/"
      />
    </Layout>
  );
};

export default AwajiPage;
